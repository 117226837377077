/* You can add global styles to this file, and also import other style files */
@import '@danielmoncada/angular-datetime-picker/assets/style/picker.min.css';
@import '../node_modules/angular-calendar/css/angular-calendar.css';

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.dark-backdrop {
  background-color: #0f03455d;
}

.add-button {
  position: fixed !important;
  bottom: 10vh;
  right: 7vw;
  z-index: 1000;
}
.add-button2 {
  position: fixed !important;
  bottom: 200px;
  right: calc(calc(5vw) - 30px);
  z-index: 1000;
}

.buttonPanel {
  display: flex;
  flex-wrap: wrap;
}

.oc-border {
  border: 1px solid #2a7189;
  color: white;
}

.oc-background {
  background-color: #2a7189;
}

.mat-slider-thumb-label {
  transform: rotate(45deg) !important;
  border-radius: 50% 50% 0 !important;
}

.mat-slider-thumb {
  transform: scale(0) !important;
}

.mat-slider-thumb-label-text {
  opacity: 1 !important;
}

.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(153, 153, 153, 0.87);
}

.owl-dt-calendar-table .owl-dt-calendar-cell-selected {
  color: rgba(255, 255, 255, 0.85);
  background-color: #2a7189;
}

.owl-dt-container-buttons {
  display: flex;
  width: 100%;
  height: 2em;
  color: #2a7189;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: #2a7189;
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0) 50%,
    rgba(255, 255, 255, 0) 75%,
    transparent 75%,
    transparent
  );
}

.action-icon {
  color: white !important;
}

.mat-mdc-card-subtitle,
.mat-mdc-card-content {
  padding-bottom: 10px;
}

.mat-mdc-card {
  padding: 15px;
}

.mat-mdc-dialog-surface {
  padding: 10px;
}
