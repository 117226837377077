@use '@angular/material' as mat;
@font-face {
  font-family: 'Ubuntu';
  src: url('../src/assets/fonts/Ubuntu-Regular.ttf');
}
@include mat.all-component-typographies();
@include mat.core();

//4o xroma
$teamworkos-blue: (
  50: #e6e6f2,
  100: #c0c1de,
  200: #9798c9,
  300: #6d6fb3,
  400: #4d50a2,
  500: #2e3192,
  600: #292c8a,
  700: #23257f,
  800: #1d1f75,
  900: #121363,
  A100: #9a9bff,
  A200: #6769ff,
  A400: #3437ff,
  A700: #1a1dff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$teamworkos-green: (
  50: #edf8f1,
  100: #d1ecdd,
  200: #b3e0c6,
  300: #94d4af,
  400: #7dca9d,
  500: #66c18c,
  600: #5ebb84,
  700: #53b379,
  800: #49ab6f,
  900: #389e5c,
  A100: #ebfff2,
  A200: #b8ffd0,
  A400: #85ffae,
  A700: #6bff9d,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$teamworkos-cyan: (
  50: #e8f8f9,
  100: #c5ecef,
  200: #9ee0e5,
  300: #77d4db,
  400: #59cad3,
  500: #3cc1cb,
  600: #36bbc6,
  700: #2eb3be,
  800: #27abb8,
  900: #1a9eac,
  A100: #e1fcff,
  A200: #aef7ff,
  A400: #7bf2ff,
  A700: #62efff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$teamworkos-grey: (
  50: #eaeaea,
  100: #c9caca,
  200: #a6a6a7,
  300: #828284,
  400: #676869,
  500: #4c4d4f,
  600: #454648,
  700: #3c3d3f,
  800: #333436,
  900: #242526,
  A100: #7eb9f4,
  A200: #4fa0f0,
  A400: #0e86ff,
  A700: #007af3,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$teamworkos-clarity: (
  50: #e5eef1,
  100: #bfd4dc,
  200: #95b8c4,
  300: #6a9cac,
  400: #4a869b,
  500: #2a7189,
  600: #256981,
  700: #1f5e76,
  800: #19546c,
  900: #0f4259,
  A100: #90d9ff,
  A200: #5dc7ff,
  A400: #2ab6ff,
  A700: #10adff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$teammworkos-app-clarity: mat.m2-define-palette($teamworkos-clarity);
$teammworkos-app-green: mat.m2-define-palette($teamworkos-green);
// The warn palette is optional (defaults to red).
$teammworkos-app-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as `color` or `typography`.

$teamworkos-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $teammworkos-app-clarity,
      accent: $teammworkos-app-green,
      warn: $teammworkos-app-warn,
    ),
    typography:
      mat.m2-define-typography-config(
        $font-family: 'Ubuntu',
      ),
    density: 0,
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.

@include mat.all-component-themes($teamworkos-theme);
$alternate-blue: mat.m2-define-palette($teamworkos-blue);
$alternate-grey: mat.m2-define-palette($teamworkos-grey);
$alternate-cyan: mat.m2-define-palette($teamworkos-cyan, 500, 900, A100);
$alternate-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $alternate-blue,
      accent: $alternate-cyan,
      warn: $alternate-grey,
    ),
    typography: mat.m2-define-typography-config(),
    density: 1,
  )
);
.alternate-theme {
  @include mat.all-component-themes($alternate-theme);
}

$custom-typography: mat.m2-define-typography-config(
  $font-family: 'Ubuntu',
);

.background-primary {
  background-color: mat.m2-get-color-from-palette($teammworkos-app-clarity);
}

.background-accent {
  background-color: mat.m2-get-color-from-palette($teammworkos-app-green);
}

.background-alternate-primary {
  background-color: mat.m2-get-color-from-palette($alternate-blue);
}

.background-alternate-accent {
  background-color: mat.m2-get-color-from-palette($alternate-cyan);
}

.background-alternate-warn {
  background-color: mat.m2-get-color-from-palette($alternate-grey);
}

.text-primary {
  color: mat.m2-get-color-from-palette($teamworkos-clarity);
}

.text-alternate-primary {
  color: mat.m2-get-color-from-palette($alternate-blue);
}

.text-accent {
  color: mat.m2-get-color-from-palette($teamworkos-green);
}

.text-alternate-accent {
  color: mat.m2-get-color-from-palette($alternate-cyan);
}
.text-alternate-warn {
  color: mat.m2-get-color-from-palette($alternate-grey);
}

input {
  color: mat.m2-get-color-from-palette($teammworkos-app-clarity) !important;
}

.mat-card-subtitle {
  color: rgb(0, 0, 0);
}

.mat-mdc-header-cell {
  color: rgb(0, 0, 0);
  font-weight: bold;
}
